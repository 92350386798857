import { Component, Inject, NO_ERRORS_SCHEMA, OnDestroy, OnInit, TemplateRef, ViewChild, AfterViewInit } from '@angular/core';
import { CommonModule, ViewportScroller } from "@angular/common";
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FaIconLibrary, FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RouterLinkActive } from "@angular/router";
import { CheckboxListComponent } from "../../common-components/checkbox-list/checkbox-list.component";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { NotificationModule, NotificationService } from "@progress/kendo-angular-notification";
import { LabelModule } from "@progress/kendo-angular-label";
import { AvatarRounded, AvatarSize, LayoutModule } from "@progress/kendo-angular-layout";
import { Observable, Subscription } from "rxjs";
import { LoggerService } from "../../shared/logger/logger.service";
import { AuthTokenService } from "../../auth/auth-token.service";
import { DiscoverOpportunitiesService } from "../discover-opportunities.service";
import { DiscoverOpportunitySearchOutcomes, OpportunityOptions, OpportunityParams } from "../../models/models.lib";
import * as _ from 'lodash';
import { OptionsAccordionComponent } from "../options-accordion/options-accordion.component";
import { ExcelModule, GridModule, PagerModule, PagerPosition, PagerType } from '@progress/kendo-angular-grid';
import { MulticheckFilterComponent } from '../../filters/multicheck-filter/multicheck-filter.component';
import {
  CompositeFilterDescriptor,
  SortDescriptor,
  distinct,
  filterBy,
  orderBy,
  process
} from '@progress/kendo-data-query';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';
import { GridSize } from "@progress/kendo-angular-grid";
import { all } from '@awesome.me/kit-787edf9aa0/icons';

// import {
//   faTriangleExclamation,
//   faPlusCircle, faAddressCard,
//   faEdit, faTrash, faBan, faDownload, faEye,
//   faMagnifyingGlass
// } from '@fortawesome/free-solid-svg-icons';
import { AppConfig, CONFIG_TOKEN } from '../../setup/config';
import { ViewOpportunityComponent } from "../view-opportunity/view-opportunity.component";
import { WindowModule } from "@progress/kendo-angular-dialog";
import { HelpButtonComponent } from "../../admin/help-info/help-button/help-button.component";
import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import { PDFViewerDownloadEvent, PDFViewerModule, PDFViewerTool } from "@progress/kendo-angular-pdfviewer";
import { HelpPopoverComponent } from '../../admin/help-info/help-popover/help-popover.component';
import { ClickLoggerDirective } from '../../directives/click-logger.directive';
import { FilenamePromptComponent } from '../../common-components/filename-prompt/filename-prompt.component';
import { ToastrService } from 'ngx-toastr';
import { WindowRef } from "@progress/kendo-angular-dialog";
import { CasiPdfViewerComponent } from '../../common-components/casi-pdf-viewer/casi-pdf-viewer.component';

@Component({
  selector: 'casi-discover-opportunities',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive,
    CheckboxListComponent,
    InputsModule,
    ButtonsModule,
    NotificationModule,
    LabelModule,
    LayoutModule,
    OptionsAccordionComponent,
    PagerModule,
    GridModule,
    MulticheckFilterComponent,
    SafeHtmlPipe,
    ViewOpportunityComponent,
    WindowModule,
    HelpButtonComponent,
    ExcelModule,
    PDFViewerModule,
    HelpPopoverComponent,
    ClickLoggerDirective,
    FilenamePromptComponent,
    CasiPdfViewerComponent
  ],
  schemas: [NO_ERRORS_SCHEMA],
  templateUrl: './discover-opportunities.component.html',
  styleUrl: './discover-opportunities.component.scss'
})
export class DiscoverOpportunitiesComponent implements OnInit, OnDestroy, AfterViewInit {
  public view: Observable<DiscoverOpportunitySearchOutcomes[]> = new Observable<DiscoverOpportunitySearchOutcomes[]>();
  public rawData: DiscoverOpportunitySearchOutcomes[] = [];
  public filter: CompositeFilterDescriptor = { logic: "and", filters: [] };
  public gridData: DiscoverOpportunitySearchOutcomes[] = filterBy(this.rawData, this.filter);
  public allData: DiscoverOpportunitySearchOutcomes[] = process(this.gridData, {
    sort: [
      { field: "title", dir: "asc" },
      { field: "short_description", dir: "asc" }
    ]
  }).data;

  form: FormGroup;
  private _subs: Subscription[] = [];
  public options: OpportunityOptions[] = [];
  public params!: OpportunityParams;
  public filterBy: string = "";
  public ids: number[] = [];

  public test: string = "";

  public pagerTypes = ["numeric", "input"];
  public type: PagerType = "numeric";
  public info = true;
  public pageSizes = true;
  public previousNext = true;
  public position: PagerPosition = "bottom";
  public pageSize = 10;
  public vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  public stickyCols: boolean = this.vw > 1300;

  public canCreate: boolean = false;
  public canUpdate: boolean = false;
  public canDestroy: boolean = false;

  public editWinOpen: boolean = false;

  public iconRoundness: AvatarRounded = "none";
  public iconSize: AvatarSize = 'medium';

  public gridSize: GridSize = 'medium';

  public viewOpportunityOpen: boolean = false;
  public viewResourcesOpen: boolean = false;
  public currentItem: DiscoverOpportunitySearchOutcomes = null as any;

  public printOpportunityOpen: boolean = false;
  public url: string = "";
  public downloadFilename = 'Download.pdf';
  public tools: PDFViewerTool[] = [
    "pager",
    "spacer",
    "zoomInOut",
    "zoom",
    "selection",
    "spacer",
    "search",
    // "open",
    "download",
    "print",
  ];

  @ViewChild("searchLogger") element: any;

  private windowRef: WindowRef | undefined;

  constructor(private logger: LoggerService,
    public authTokenService: AuthTokenService,
    private route: ActivatedRoute,
    public dataService: DiscoverOpportunitiesService,
    private notificationService: NotificationService,
    private fb: FormBuilder,
    public faIcons: FaIconLibrary,
    @Inject(CONFIG_TOKEN) private config: AppConfig,
    private router: Router,
    private toastr: ToastrService,
    private viewportScroller: ViewportScroller

  ) {
    this.form = this.fb.group({ search: [null, []] });
    this.params = OpportunityParams.create({});
    faIcons.addIcons(...all);
    // faIcons.addIcons(
    //   faTriangleExclamation,
    //   faPlusCircle, faAddressCard, faEdit,
    //   faTrash,
    //   faBan, faDownload, faEye,
    //   faMagnifyingGlass
    // );

  //  this.allData = this.allData.bind(this);

    window.onresize = (event) => {
      this.vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      this.stickyCols = this.vw > 1300;
    };

  }

  // public allData(): ExcelExportData {
  //   const result: ExcelExportData = {
  //     data: process(this.gridData, {
  //       sort: [
  //         { field: "title", dir: "asc" },
  //         { field: "description", dir: "asc" }
  //       ]
  //     }).data
  //   };
  //   return result;
  // }

  get f() {
    return this.form.controls;
  }

  get search() {
    return this.form.get('search');
  }

  //#region Ng Handlers

  ngOnInit(): void {
    this.logger.debug('ngOnInit...');
    this.form.patchValue(this.params);
    //this.loadData();
    this.ids = this.dataService.getIds();
    this.filterBy = this.dataService.getFilterBy();
    if (this.ids.length === 0 && !this.filterBy)
      this.loadData();

    this._subs.push(this.route.data.subscribe(({ options }) => {
      this.options = _.map(options, o => OpportunityOptions.create(o));
      this.logger.debug('ngOnInit: this.options: ', this.options);
    }));

    this._subs.push(this.dataService.subscribe((outcomes) => {
      // this.logger.debug('ngOnInit:outcomes: ', outcomes);
      this.rawData = outcomes;
      this._loadData();
    }))
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.viewportScroller.scrollToPosition([0, 0]));
  }


  ngOnDestroy(): void {
    this.logger.debug('ngOnDestroy...');
    this._subs.forEach(s => s.unsubscribe());
    this.dataService.setFilterBy("");
    this.dataService.setIds([]);
  }

  //#endregion

  buildImageLink(imageName: string): string {
    // this.logger.debug('buildImageLink: imageName:', imageName);
    if (imageName)
      return `${this.config.iconBaseDir}${imageName}${this.config.iconEnding}`;
    return 'Not Set';
  }

  public viewResources(dataItem: any): void {
    this.logger.debug("viewResources: ", dataItem);
    this.currentItem = dataItem;
    this.viewResourcesOpen = true;
  }

  public closeViewResources(): void {
    this.logger.debug('closeViewResources...');
    this.viewResourcesOpen = false;
  }

  onViewClick(dataItem: any): void {
    this.logger.debug("onViewClick: ", dataItem);
    this.currentItem = dataItem;
    this.viewOpportunityOpen = true;
  }

  public closeViewOpportunity(): void {
    this.logger.debug('closeViewOpportunity...');
    this.viewOpportunityOpen = false;
    this.closeWindowRef();
  }

  printPDF(args: any): void {
    this.logger.debug("printPDF: ");
    const { url } = args;
    this.url = url;
    this.downloadFilename = `${(this.currentItem.title as string)}.pdf`;
    this.printOpportunityOpen = true;
  }

  public closePrintOpportunity(): void {
    this.closePdfWindow();
  }

  public closeViewer(): void {
    this.closePdfWindow();
  }

  public loadData(): void {
    this.logger.debug('loadData...', this.params);
    this.dataService.doSearch(this.params);
    this.element?.manualLog(`discoverOpportunties:search:${JSON.stringify(this.params)}`)
  }

  public onDownload(event: PDFViewerDownloadEvent): void {
    this.logger.debug(`download event`, event);
  }

  //#region Grid handlers

  onClearFilters(gridName: string) {
    this.logger.debug("onClearFilters: ", gridName);
    this.filter = { logic: "and", filters: [] };
    this.loadData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.logger.debug("sortChange: ", sort);
    // this.sort = sort;
    this.loadData();
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    // this.logger.debug('filterChange: filter: ', filter);
    this.filter = filter;
    this.loadData();
  }

  public distinctPrimitive(fieldName: string): any[] {
    // this.logger.debug('distinctPrimitive: fieldName: ', fieldName);
    return distinct(this.gridData, fieldName).map((item: any) => item[fieldName]);
  }


  //#endregion

  //#region Grid functions

  public onChange(args: any): void {
    this.logger.debug('onChange...', args);
    // this.test = JSON.stringify(this.params)
    this.loadData();
  }

  public enableStickyCols(args: any): void {
    this.logger.debug(`enableStickyCols...`);
    setTimeout(() => this.stickyCols = true);
  }

  public disableStickyCols() {
    this.logger.debug(`disableStickyCols...`);
    setTimeout(() => this.stickyCols = false);
  }

  //#endregion

  //#region Private functions

  private _loadData(): void {
    // this.logger.debug('loadData...');
    this.gridData = orderBy(filterBy(this.rawData, this.filter), []);
    this.allData = process(this.gridData, {
      sort: [
        { field: "title", dir: "asc" },
        { field: "short_description", dir: "asc" }
      ]
    }).data;
    this.pageSize = this.gridData.length;
  }

  private closePdfWindow(): void {
    this.logger.debug('closePdfWindow...');
    this.closeWindowRef();
  }

  private closeWindowRef(): void {
    this.logger.debug('closeWindowRef...', this.windowRef);
    if (this.windowRef) {
      this.windowRef.close();
      this.windowRef = undefined;
    }
    this.printOpportunityOpen = false;
  }

  //#endregion

}

import { Component, EventEmitter, Inject, Input, NO_ERRORS_SCHEMA, OnDestroy, OnInit, Output } from '@angular/core';
import { DiscoverOpportunitySearchOutcomes, OpportunityOutcome } from "../../models/models.lib";
import { CommonModule } from "@angular/common";
import { RouterLink, RouterLinkActive } from "@angular/router";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { LabelModule } from "@progress/kendo-angular-label";
import { AvatarRounded, AvatarSize, LayoutModule } from "@progress/kendo-angular-layout";
import { LoggerService } from "../../shared/logger/logger.service";
import { AppConfig, CONFIG_TOKEN } from "../../setup/config";
import { GridModule, GridSize, PagerPosition, PagerType } from "@progress/kendo-angular-grid";
import { MulticheckFilterComponent } from "../../filters/multicheck-filter/multicheck-filter.component";
import {
  CompositeFilterDescriptor,
  distinct,
  filterBy,
  GroupDescriptor,
  orderBy,
  SortDescriptor
} from "@progress/kendo-data-query";
import { ShowMoreLessComponent } from "../../common-components/show-more-less/show-more-less.component";
import { JsReportServiceService } from "../../commonServices/js-report-service.service";
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  faPlus, faMinus, faPlusMinus, faSpinner
} from '@fortawesome/free-solid-svg-icons';
import { DisableAfterClickDirective } from '../../directives/disable-after-click.directive';
import { BehaviorSubject } from 'rxjs';
import {MeService} from "../../auth/me.service";
import * as uuid from "uuid";

@Component({
  selector: 'casi-view-opportunity',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink, RouterLinkActive,
    InputsModule,
    ButtonsModule,
    LabelModule,
    LayoutModule,
    GridModule,
    MulticheckFilterComponent,
    ShowMoreLessComponent,
    FontAwesomeModule,
    DisableAfterClickDirective
  ],
  schemas: [NO_ERRORS_SCHEMA],
  templateUrl: './view-opportunity.component.html',
  styleUrl: './view-opportunity.component.scss'
})
export class ViewOpportunityComponent implements OnInit, OnDestroy {

  @Input({ required: true }) opportunity: DiscoverOpportunitySearchOutcomes = null as any;
  @Input() hideCloseBtn: boolean = false;
  @Output() onClose = new EventEmitter<void>();
  @Output() onPrint = new EventEmitter<any>();
  @Output() onPrintClick = new EventEmitter<void>();

  public id: string;

  public iconRoundness: AvatarRounded = "none";
  public iconSize: AvatarSize = 'medium';

  public rawData: OpportunityOutcome[] = [];
  public filter: CompositeFilterDescriptor = { logic: "and", filters: [] };
  public gridData: OpportunityOutcome[] = filterBy(this.rawData, this.filter);
  public pagerTypes = ["numeric", "input"];
  public type: PagerType = "numeric";
  public info = true;
  public pageSizes = true;
  public previousNext = true;
  public position: PagerPosition = "bottom";
  public pageSize = 10;
  public gridSize: GridSize = 'medium';
  // public isLoggedIn: boolean = false;

  public enablePrintPdfButton: boolean = true;

  public sort: SortDescriptor[] = [
    { field: "outcome", dir: "asc" },
    { field: "group", dir: "asc" }
  ];

  public groups: GroupDescriptor[] = [
    { field: 'outcome' },
    { field: 'group' }
  ];

  disableState = false;

  disabledState$ = new BehaviorSubject<boolean>(false);

  constructor(
    private logger: LoggerService,
    private jsReportService: JsReportServiceService,
    @Inject(CONFIG_TOKEN) private config: AppConfig,
    public faIcons: FaIconLibrary,
    private me: MeService
  ) {
    faIcons.addIcons(faPlus, faMinus, faPlusMinus, faSpinner);
    this.id = uuid.v4();
  }

  //#region Ng Handlers
  ngOnInit(): void {
    this.logger.debug('ngOnInit...', this.me.isLoggedIn());
    // this.isLoggedIn = this.me.isLoggedIn();
    this.rawData = this.opportunity.opportunity_outcome ? this.opportunity.opportunity_outcome.map(o => OpportunityOutcome.create(o)) : [];
    this.loadData();
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnInit...');
  }

  //#endregion

  //#region Grid handlers

  onClearFilters(gridName: string) {
    this.logger.debug("onClearFilters: ", gridName);
    this.filter = { logic: "and", filters: [] };
    this.loadData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.logger.debug("sortChange: ", sort);
    this.sort = sort;
    this.loadData();
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    // this.logger.debug('filterChange: filter: ', filter);
    this.filter = filter;
    this.loadData();
  }

  public distinctPrimitive(fieldName: string): any[] {
    // this.logger.debug('distinctPrimitive: fieldName: ', fieldName);
    return distinct(this.gridData, fieldName).map((item: any) => item[fieldName]);
  }

  private loadData(): void {
    // this.logger.debug('loadData...');
    this.gridData = orderBy(filterBy(this.rawData, this.filter), this.sort);
    this.pageSize = this.gridData.length;
  }

  //#endregion

  closeWindow(): void {
    this.logger.debug(`closeWindow...`);
    this.onClose.emit();
  }

  printPDF(): void {
    this.logger.debug(`printPDF...`);
    this.disableState = true;
    this.onPrintClick.emit();
    this.jsReportService.printOpportunity(this.opportunity.id, false)
      .then(report => {
        const blob = new Blob([report], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        // this.closeWindow();
        this.onPrint.emit({ url });
        this.disabledState$.next(false);
        this.disableState = false;
        // window.open(url);
      })
      .catch(e => this.logger.error("printPDF", e))
  }


  buildImageLink(imageName: string | undefined): string {
    return `${this.config.iconBaseDir}${imageName}${this.config.iconEnding}`;
  }

  getIconName(field: string, value: string) {
    let icon = undefined;
    for (let item of this.gridData) {
      if ((item as any)[field] == value) {
        icon = item.icon_name;
        break;
      }
    }
    return icon;
  }

  getNumber(field: string, value: string) {
    let items = this.gridData.filter(gd => (gd as any)[field] == value);
    let negatives = items.filter(i => i.negative).length;
    let positives = items.filter(i => i.positive).length;
    let neutrals = items.filter(i => i.neutral).length;
    let char177 = String.fromCharCode(177);

    let returning = (positives > 0 ? `+${positives}` : '') + (negatives > 0 ? `-${negatives}` : '') + (neutrals > 0 ? `${char177}${neutrals}` : '');
    return returning ? `(${returning})` : '';
  }


}

import { Component, OnInit } from '@angular/core';
import { Route, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HelpButtonComponent } from '../../../admin/help-info/help-button/help-button.component';
import { DiscoverOpportunityComponent } from '../discover-opportunity/discover-opportunity.component';
import { ClickLoggerDirective } from '../../../directives/click-logger.directive';
import { AuthTokenService } from '../../../auth/auth-token.service';

@Component({
  selector: 'casi-search-australia-link',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink, RouterLinkActive,
    HelpButtonComponent,
    DiscoverOpportunityComponent,
    ClickLoggerDirective
  ],
  templateUrl: './search-australia-link.component.html',
  styleUrl: './search-australia-link.component.scss'
})
export class SearchAustraliaLinkComponent {

  public isLoggedIn: boolean = this.authTokenService.loggedIn;

  constructor(public authTokenService: AuthTokenService) { }

}

import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {APP_CONFIG} from "../setup/config";
import {map, tap} from "rxjs/operators";
import {ApiService} from "../api/api.service";
import {DiscoverOpportunitySearchOutcomes, ResourceOptions} from "../models/models.lib";
import { environment } from '../../environments/environment';

const { apiUrl } = environment;

export abstract class CommonDiscoverResourcesService extends BehaviorSubject<Array<any>>{
  public loading: boolean = false;

  constructor(
    private http: HttpClient,
  ) {
    super([]);
  }

  public query(): void {
    this.fetch().subscribe((x) => {
      console.log('query: x: ', x);
      return super.next(x);
    });
  }

  protected fetch(): Observable<ResourceOptions[]> {
    this.loading = true;

    return this.http.get(encodeURI(`${apiUrl}/resource/options`)) //to change
      .pipe(
        map((response: any, i) => {
          return <ResourceOptions[]>response;
        }),
        map(r => r.map(x => ResourceOptions.create(x))),
        tap(() => (this.loading = false))
      )
  }
}

@Injectable({
  providedIn: 'root'
})
export class DiscoverResourcesService  extends CommonDiscoverResourcesService{

  constructor(
    http: HttpClient,
    private apiService: ApiService
  ) {
    super(http);
  }

  getOptions(){
    return this.apiService.getHttpCall('resource/options', 'get');
  }

  exploreOpportunities(id: number): Promise<DiscoverOpportunitySearchOutcomes[]> {
    return this.apiService.getHttpCallAsPromise(`resource/explore/opportunities/${id}`, 'exploreOpportunities');
  }
}

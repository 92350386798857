import {
  Component,
  Inject,
  NO_ERRORS_SCHEMA,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {AppEnities, FormModes} from "../../common/constants.lib";
import {CommonModule} from "@angular/common";
import {FaIconLibrary, FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {ActivatedRoute, Router, RouterLink, RouterLinkActive} from "@angular/router";
import {InputsModule} from "@progress/kendo-angular-inputs";
import {ButtonsModule} from "@progress/kendo-angular-buttons";
import {NotificationModule, NotificationService} from "@progress/kendo-angular-notification";
import {LabelModule} from "@progress/kendo-angular-label";
import {AvatarRounded, AvatarSize, LayoutModule} from "@progress/kendo-angular-layout";
import {ExcelModule, GridModule, PagerModule, PagerPosition, PagerType} from "@progress/kendo-angular-grid";
import {MulticheckFilterComponent} from "../../filters/multicheck-filter/multicheck-filter.component";
import {SafeHtmlPipe} from "../../pipes/safe-html.pipe";
import {DialogService, DialogsModule, WindowModule} from "@progress/kendo-angular-dialog";
import {HelpButtonComponent} from "../../admin/help-info/help-button/help-button.component";
import {HelpPopoverComponent} from "../../admin/help-info/help-popover/help-popover.component";
import {ClickLoggerDirective} from "../../directives/click-logger.directive";
import {LoggerService} from "../../shared/logger/logger.service";
import {AuthTokenService} from "../../auth/auth-token.service";
import {AppConfig, CONFIG_TOKEN} from "../../setup/config";

import {faCheck, faDownload, faEdit} from "@fortawesome/free-solid-svg-icons";
import {ExcelExportData} from "@progress/kendo-angular-excel-export";
import {
  CompositeFilterDescriptor,
  distinct,
  filterBy,
  orderBy,
  process,
  SortDescriptor
} from "@progress/kendo-data-query";
import {MeService} from "../../auth/me.service";
import {ReviewOpportunityService} from "../review-opportunity.service";
import * as _ from "lodash";
import {Observable, Subscription} from "rxjs";
import {ReviewOpportunity} from "../../models/models.lib";
import {EditReviewComponent} from "../edit-review/edit-review.component";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import { EditReviewModalComponent } from '../edit-review-modal/edit-review-modal.component';
import {lockBodyScroll, unlockBodyScroll} from "../../common/utils.lib";

const { REVIEW } = AppEnities;

@Component({
  selector: 'casi-review-opportunity-list',
  standalone: true,
  imports: [ CommonModule,
    FontAwesomeModule,
    RouterLink, RouterLinkActive,
    InputsModule,
    ButtonsModule,
    NotificationModule,
    LabelModule,
    LayoutModule,
    PagerModule,
    GridModule,
    MulticheckFilterComponent,
    SafeHtmlPipe,
    WindowModule,
    DialogsModule,
    HelpButtonComponent,
    ExcelModule,
    HelpPopoverComponent,
    ClickLoggerDirective,
    EditReviewComponent,
    FormsModule, ReactiveFormsModule,
    EditReviewModalComponent
  ],
  schemas: [NO_ERRORS_SCHEMA],
  templateUrl: './review-opportunity-list.component.html',
  styleUrl: './review-opportunity-list.component.scss'
})
export class ReviewOpportunityListComponent implements OnInit, OnDestroy {

  @ViewChild("appendTo", { read: ViewContainerRef }) appendTo: ViewContainerRef | undefined;

  public canCreate: boolean = false;
  public canUpdate: boolean = false;
  public view: Observable<ReviewOpportunity[]> = new Observable<ReviewOpportunity[]>();
  public rawData: ReviewOpportunity[] = [];
  public filter: CompositeFilterDescriptor = { logic: "and", filters: [] };
  public gridData: ReviewOpportunity[] = filterBy(this.rawData, this.filter);
  public vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  public stickyCols: boolean = this.vw > 1300;
  private _subs: Subscription[] = [];

  public pagerTypes = ["numeric", "input"];
  public type: PagerType = "numeric";
  public info = true;
  public pageSizes = true;
  public previousNext = true;
  public position: PagerPosition = "bottom";
  public pageSize = 10;

  public iconRoundness: AvatarRounded = "none";
  public iconSize: AvatarSize = 'large';

  public editReviewOpen: boolean = false;
  public completeReviewOpen: boolean = false;

  public currentItem: ReviewOpportunity = null as any;
  form: FormGroup;
  private scrollPosition = { top: 0, left: 0 };

  constructor(private logger: LoggerService,
              public authTokenService: AuthTokenService,
              private route: ActivatedRoute,
              public dataService: ReviewOpportunityService,
              private notificationService: NotificationService,
              public faIcons: FaIconLibrary,
              @Inject(CONFIG_TOKEN) private config: AppConfig,
              private router: Router,
              private me: MeService,
              private fb: FormBuilder,
              private renderer: Renderer2,
              private dialogService: DialogService
  ) {
    faIcons.addIcons(faEdit,  faDownload, faCheck);
    this.allData = this.allData.bind(this);
    this.form = this.fb.group({
      review_comment: ['', []]
    });

    window.onresize = (event) => {
      this.vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      this.stickyCols = this.vw > 1300;
    };

  }

  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.gridData, {
        sort: [
          { field: "title", dir: "asc" },
          { field: "description", dir: "asc" }
        ]
      }).data
    };
    return result;
  }

  get f() {
    return this.form.controls;
  }
  get review_comment() {
    return this.form.get('review_comment');
  }

  //#region Ng Handlers

  ngOnInit(): void {
    this.logger.debug('ngOnInit...');
    const { can_create, can_update } = this.me.allICanDo(REVIEW);

    this.logger.debug('ngOnInit:permissions: ', can_create, can_update);
    this.canCreate = can_create;
    this.canUpdate = can_update;

    this.dataService.query();
    this._subs.push(this.dataService.subscribe((outcomes) => {
      this.rawData = outcomes;
      this.loadData();
    }));
  }

  ngOnDestroy(): void {
    this.logger.debug('ngOnDestroy...');
    this._subs.forEach(s => s.unsubscribe());
    unlockBodyScroll(this.renderer, this.scrollPosition);
  }

  //#endregion

  //#region view methods

  buildImageLink(imageName: string): string {
    // this.logger.debug('buildImageLink: imageName:', imageName);
    if (imageName)
      return `${this.config.iconBaseDir}${imageName}${this.config.iconEnding}`;
    return 'Not Set';
  }

  edit(dataItem: any): void {
    this.logger.debug("edit: ", dataItem);
    this.currentItem = dataItem;
    this.editReviewOpen = true;
    lockBodyScroll(this.renderer, this.scrollPosition);
  }


  public closeEditReview(): void {
    this.logger.debug('closeEditReview...');
    this.editReviewOpen = false;
    unlockBodyScroll(this.renderer, this.scrollPosition);
  }

  complete(dataItem: any): void {
    this.logger.debug("edit: ", dataItem);
    this.currentItem = dataItem;
    this.currentItem.review_comment = undefined;
    this.form.patchValue({review_comment: this.currentItem.review_comment});
    this.completeReviewOpen = true;
  }

  closeCompleteReview(status: string): void {
    this.logger.debug("closeCompleteReview: ", status, this.currentItem);
    this.completeReviewOpen = false;
    this.currentItem.review_comment = this.review_comment?.value;
    if (status === 'complete')
      this.handleComplete(this.currentItem);

  }

  public onItemSave(args: any): void {
    this.logger.debug('onItemSave...', args);
    this.handleSaveAndClose(args);
  }
  //#end region

  //#region Grid handlers

  onClearFilters(gridName: string) {
    this.logger.debug("onClearFilters: ", gridName);
    this.filter = { logic: "and", filters: [] };
    this.loadData();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.logger.debug("sortChange: ", sort);
    // this.sort = sort;
    this.loadData();
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.logger.debug('filterChange: filter: ', filter);
    this.filter = filter;
    this.loadData();
  }

  public distinctPrimitive(fieldName: string): string[] {
    // this.logger.debug('distinctPrimitive: fieldName: ', fieldName);
    return distinct(this.gridData, fieldName).map((item: any) => `${item[fieldName]}\u200B`);
  }

  public enableStickyCols(args: any): void {
    this.logger.debug(`enableStickyCols...`);
    setTimeout(() => this.stickyCols = true);
  }

  public disableStickyCols() {
    this.logger.debug(`disableStickyCols...`);
    setTimeout(() => this.stickyCols = false);
  }
  //#endregion

  //#region privates
  private loadData(): void {
    this.logger.debug('loadData...');
    this.gridData = orderBy(filterBy(this.rawData, this.filter), []);
    this.pageSize = this.gridData.length;
  }

  private handleComplete(args: any): void {
    if (args && !args.opportunity_review_id) {
      this.logger.error('handleComplete: No id given');
      return;
    }
    this.dataService.setComplete(args)
      .then(resp => {
        this.logger.debug('handleComplete:resp: ', resp);
        this.notify("Review Completed");
        this.dataService.query();
      })
      .catch(err => {
        this.logger.error('handleComplete:err: ', err);
        this.notify("Error Completing Review", true);
      });
  }

  private handleSaveAndClose(args: any): void {
    this.logger.debug('handleSaveAndClose...', args);
    const { data } = args;
    this.dataService.update(data)
      .then(resp => {
        this.editReviewOpen = false;
        this.notify('Review Updated')
        this.dataService.query();

      })
      .catch(err => {
        this.editReviewOpen = false;
        this.logger.error('handleSaveAndClose:error: ', err);
        this.notify("Updating Review", true);
      })
      // .finally(() => this.editReviewOpen = false);
  }

  private notify(msg: string, error: boolean = false): void {
    this.logger.debug("notify", msg);
    this.notificationService.show({
      appendTo: this.appendTo,
      content: msg,
      animation: { type: "fade", duration: 300 },
      type: { style: !error ? "success" : "error", icon: true },
      position: {
        horizontal: "right",
        vertical: "top"
      },
      height: 50,
      // width: 150,
      hideAfter: 2000
    });
  }
  //#endregion
}

<form class="k-form-inline">
  <fieldset>
    <div class="k-form-field row">
      <label class="form-label col-3">Title</label>
      <div class="col-12 col-md-8" style="font-size: 16px; font-weight: bold;">
        <a [href]="opportunity.organisation_url" style="cursor: pointer; text-decoration: underline; font-weight: bold; font-size: 15px;" target=”_blank” *ngIf="opportunity.organisation_url">{{opportunity.title}}</a>
        <span *ngIf="!opportunity.organisation_url" style="font-weight: bold; font-size: 15px;">{{opportunity.title}}</span>
      </div>

    </div>

    <div class="k-form-field row">
      <label class="form-label col-12 col-md-3">Organisation</label>
      <div class="col-12 col-md-8">
        <span style="font-size: 16px; font-weight: bold;"> {{opportunity.organisation_name}}</span>
      </div>
    </div>

<!--    <ul class="nav nav-tabs" id="opportunityTabs" role="tablist" style="font-size: 16px; border-bottom: 1px solid #dee2e6 !important;">-->
<!--      <li class="nav-item" role="presentation">-->
<!--        <a class="nav-link active" id="information-tab{{id}}" data-bs-toggle="tab" data-bs-target="#information{{id}}" role="tab" aria-controls="information{{id}}"-->
<!--          aria-selected="true">Opportunity Information</a>-->
<!--      </li>-->
<!--      <li class="nav-item" role="presentation">-->
<!--        <a class="nav-link" id="outcome-tab{{id}}" data-bs-toggle="tab" data-bs-target="#outcome{{id}}" type="button" role="tab" aria-controls="outcome{{id}}"-->
<!--          aria-selected="false">Project Outcome Detail</a>-->
<!--      </li>-->
<!--    </ul>-->

    <ul class="nav nav-tabs" id="opportunityTabs{{id}}" role="tablist" style="font-size: 16px; border-bottom: 1px solid #dee2e6 !important;">
      <li class="nav-item" role="presentation">
        <a class="nav-link active" id="information-tab{{id}}" data-bs-toggle="tab" href="#information{{id}}" role="tab" aria-controls="information{{id}}" aria-selected="true">
          Opportunity Information
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="outcome-tab{{id}}" data-bs-toggle="tab" href="#outcome{{id}}" role="tab" aria-controls="outcome{{id}}" aria-selected="false">
          Project Outcome Detail
        </a>
      </li>
    </ul>

    <!-- Tab Content -->
    <div class="tab-content" id="opportunityTabsContent" style="padding: 40px 20px; border: 1px solid #dee2e6; border-top: none; margin-bottom: 15px;">
      <div class="tab-pane fade show active" id="information{{id}}" role="tabpanel" aria-labelledby="information-tab{{id}}">

        <div class="k-form-field row">
          <label class="form-label col-12 col-md-3">Summary</label>
          <div class="col-12 col-md-8" style="padding: 5px; border: 1px solid #dee2e6; border-radius: 5px; min-height: 35px;">
            {{opportunity.short_description}}</div>
        </div>

        <div class="k-form-field row">
          <label class="form-label col-12 col-md-3">More Information</label>
          <div class="col-12 col-md-8" style="padding: 5px; border: 1px solid #dee2e6; border-radius: 5px; min-height: 35px;">
            <casi-show-more-less [content]="opportunity.full_description"></casi-show-more-less>
          </div>
        </div>

        <div class="k-form-field row">
          <label class="form-label col-12 col-md-3">Market Opportunities</label>
          <div class="col-12 col-md-8" style="padding: 5px; border: 1px solid #dee2e6; border-radius: 5px; min-height: 35px;">
            <div [ngStyle]="{'text-align':'left'}">
              <div style="text-align: center; margin-right: 15px; max-width: 120px;">
                <kendo-avatar [size]="iconSize" [rounded]="iconRoundness"
                  [imageSrc]="buildImageLink(opportunity.classification_icon_name)"  *ngIf="opportunity.classification_icon_name">˝</kendo-avatar>
                <div>{{opportunity.classification_description}}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="k-form-field row">
          <label class="form-label col-12 col-md-3">Industries</label>
          <div class="col-12 col-md-8" style="padding: 5px; border: 1px solid #dee2e6; border-radius: 5px; min-height: 35px;">
            <div style="display: flex" class="row">
              <div *ngFor="let industry of opportunity.industries"
                style="text-align: center; margin-right: 15px; max-width: 120px;">
                <kendo-avatar [size]="iconSize" [rounded]="iconRoundness" [imageSrc]="buildImageLink(industry.icon_name)"  *ngIf="industry.icon_name">˝</kendo-avatar>
                <div>{{industry.description}}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="k-form-field row">
          <label class="form-label col-12 col-md-3">Project Goals</label>
          <div class="col-12 col-md-8" style="padding: 5px; border: 1px solid #dee2e6; border-radius: 5px; min-height: 35px;">
            <div style="display: flex" class="row">
              <div *ngFor="let goal of opportunity.opportunity_goals"
                style="text-align: center; margin-right: 15px; max-width: 120px;">
                <kendo-avatar [size]="iconSize" [rounded]="iconRoundness" [imageSrc]="buildImageLink(goal.icon_name)"  *ngIf="goal.icon_name">˝</kendo-avatar>
                <div>{{goal.goal}}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="k-form-field row">
          <label class="form-label col-12 col-md-3">Location</label>
          <div class="col-12 col-md-8" style="padding: 5px; border: 1px solid #dee2e6; border-radius: 5px; min-height: 35px;">
            {{opportunity.stateList}}</div>
        </div>

        <div class="k-form-field row">
          <label class="form-label col-12 col-md-3">Eligibility</label>
          <div class="col-12 col-md-8" style="padding: 5px; border: 1px solid #dee2e6; border-radius: 5px; min-height: 35px;">
            <casi-show-more-less [content]="opportunity.elegibility_text"></casi-show-more-less>
          </div>
        </div>

        <div class="k-form-field row">
          <label class="form-label col-12 col-md-3">Service Providers</label>
          <div class="col-12 col-md-8" style="padding: 5px; border: 1px solid #dee2e6; border-radius: 5px; min-height: 35px;">
            <casi-show-more-less [content]="opportunity.service_providers"></casi-show-more-less>
          </div>
        </div>

        <div class="k-form-field row">
          <label class="form-label col-12 col-md-3">Benefits</label>
          <div class="col-12 col-md-8" style="padding: 5px; border: 1px solid #dee2e6; border-radius: 5px; min-height: 35px;">
            <casi-show-more-less [content]="opportunity.benefits"></casi-show-more-less>
          </div>
        </div>

        <div class="k-form-field row">
          <label class="form-label col-12 col-md-3">Negatives</label>
          <div class="col-12 col-md-8" style="padding: 5px; border: 1px solid #dee2e6; border-radius: 5px; min-height: 35px;">
            <casi-show-more-less [content]="opportunity.negatives"></casi-show-more-less>
          </div>
        </div>

        <div class="k-form-field row">
          <label class="form-label col-12 col-md-3">Risks</label>
          <div class="col-12 col-md-8" style="padding: 5px; border: 1px solid #dee2e6; border-radius: 5px; min-height: 35px;">
            <casi-show-more-less [content]="opportunity.risks"></casi-show-more-less>
          </div>
        </div>

        <div class="k-form-field row">
          <label class="form-label col-12 col-md-3">Costs</label>
          <div class="col-12 col-md-8" style="padding: 5px; border: 1px solid #dee2e6; border-radius: 5px; min-height: 35px;">
            <casi-show-more-less [content]="opportunity.costs"></casi-show-more-less>
          </div>
        </div>

        <div class="k-form-field row">
          <label class="form-label col-12 col-md-3">Attribution</label>
          <div class="col-12 col-md-8" style="padding: 5px; border: 1px solid #dee2e6; border-radius: 5px; min-height: 35px;">
            <casi-show-more-less [content]="opportunity.attribution"></casi-show-more-less>
          </div>
        </div>

        <div class="k-form-field row" *ngIf="opportunity.funding_scheme_start || opportunity.funding_scheme_end">
          <label class="form-label col-12 col-md-3">Funding Scheme</label>
          <div class="col-12 col-md-8">
            <label class="me-2">Start:</label>
            <label class="col-4 col-md-2">{{opportunity.funding_scheme_start | date: 'dd/MM/yyyy'}}</label>
            <label class="me-2">End:</label>
            <label>{{opportunity.funding_scheme_end | date: 'dd/MM/yyyy'}}</label>

          </div>
        </div>

        <div class="k-form-field row">
          <label class="form-label col-12 col-md-3">Potential Financial Benefits</label>
          <div class="col-12 col-md-8" style="padding: 5px; border: 1px solid #dee2e6; border-radius: 5px; min-height: 35px;">
            <casi-show-more-less [content]="opportunity.potential_financial_benefits"></casi-show-more-less>
          </div>
        </div>

        <div class="k-form-field row">
          <label class="form-label col-12 col-md-3">Practices</label>
          <div class="col-12 col-md-8 standard-font-size"
            style="padding: 5px; border: 1px solid #dee2e6; border-radius: 5px; min-height: 35px;">
            <div style="margin-bottom: 20px" *ngFor="let p of opportunity.opportunity_practices"
              [innerHTML]="p.practice_description"></div>
          </div>
        </div>

        <div class="k-form-field row">
          <label class="form-label col-12 col-md-3">References</label>
          <div class="col-12 col-md-8" style="padding: 5px; border: 1px solid #dee2e6; border-radius: 5px; min-height: 35px;">
            <div style="margin-bottom: 20px" *ngFor="let rl of opportunity.opportunity_reference_links">
              <a [href]="rl.url" target="_blank"
                style="cursor: pointer; text-decoration: underline">{{rl.description}}</a>
            </div>
          </div>
        </div>

        <div class="k-form-field row">
          <label class="form-label col-12 col-md-3">Resources</label>
          <div class="col-12 col-md-8" style="padding: 5px; border: 1px solid #dee2e6; border-radius: 5px; min-height: 35px;">
            <div style="margin-bottom: 20px" *ngFor="let item of opportunity.opportunity_resources">
              <a [href]="item.url" target="_blank" style="cursor: pointer; text-decoration: underline">{{item.title}}</a>
            </div>
          </div>
        </div>


      </div>
      <div class="tab-pane fade" id="outcome{{id}}" role="tabpanel" aria-labelledby="outcome-tab{{id}}">
        <div class="add-footer-margin">

          <kendo-grid [kendoGridBinding]="gridData" [sort]="sort" [sortable]="true" [group]="groups" [groupable]="true"
            [resizable]="true" [filter]="filter" filterable="menu" (filterChange)="filterChange($event)"
            (sortChange)="sortChange($event)" [pageSize]="pageSize" [pageable]="true" [style.maxHeight.px]="2000"
            [size]="gridSize">




            <kendo-grid-column field="outcome" title="Outcome" [width]="140" [style]="{'vertical-align': 'top'}">
              <ng-template kendoGridGroupHeaderTemplate let-field="field" let-value="value">
                <strong> Outcome: {{ value }} {{getNumber(field, value)}}</strong>
                <img src="assets/images/{{getIconName(field, value)}}.png"
                  style="float: left;width: 40px;margin-left: 10px; margin-top: 2px;">
              </ng-template>
              <ng-template kendoGridFilterMenuTemplate let-column="column" let-dataItem let-filter="filter"
                let-filterService="filterService">
                <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
                  [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="group" title="Group" [width]="130" [style]="{'vertical-align': 'top'}">
              <ng-template kendoGridGroupHeaderTemplate let-group let-field="field" let-value="value">
                <strong> Group: {{ value }} {{getNumber(field, value)}}</strong>
              </ng-template>
              <ng-template kendoGridFilterMenuTemplate let-column="column" let-dataItem let-filter="filter"
                let-filterService="filterService">
                <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
                  [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="detailed_outcome" title="Detailed Outcome" [width]="200"
              [style]="{'vertical-align': 'top'}">
              <ng-template kendoGridFilterMenuTemplate let-column="column" let-dataItem let-filter="filter"
                let-filterService="filterService">
                <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
                  [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Impact" [width]="100"
              [style]="{'vertical-align': 'middle', 'text-align': 'center', 'font-size': '26px'}">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">

                <fa-icon icon="plus" *ngIf="dataItem.positive" [style]="{'color': '#1e7716'}"></fa-icon>
                <fa-icon icon="minus" *ngIf="dataItem.negative" [style]="{'color': '#e90c22'}"></fa-icon>
                <fa-icon icon="plus-minus" *ngIf="dataItem.neutral" [style]="{'color': '#f56811'}"></fa-icon>
              </ng-template>
            </kendo-grid-column>

            <!-- <kendo-grid-column title="Positive" [width]="100"
              [style]="{'vertical-align': 'middle', 'text-align': 'center', 'color': '#1e7716', 'font-size': '26px'}">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">

                <fa-icon icon="plus" *ngIf="dataItem.positive"></fa-icon>

              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Negative" [width]="100"
              [style]="{'vertical-align': 'middle', 'text-align': 'center', 'color': '#e90c22', 'font-size': '26px'}">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <fa-icon icon="minus" *ngIf="dataItem.negative"></fa-icon>

              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column title="Neutral" [width]="100"
              [style]="{'vertical-align': 'middle', 'text-align': 'center', 'color': '#f56811', 'font-size': '26px'}">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <fa-icon icon="plus-minus" *ngIf="dataItem.neutral"></fa-icon>

              </ng-template>
            </kendo-grid-column> -->

            <kendo-grid-column field="summary" title="Comment" [width]="200" [style]="{'vertical-align': 'top'}">
              <ng-template kendoGridFilterMenuTemplate let-column="column" let-dataItem let-filter="filter"
                let-filterService="filterService">
                <multicheck-filter [isPrimitive]="true" [field]="column.field" [filterService]="filterService"
                  [currentFilter]="filter" [data]="distinctPrimitive(column.field)"></multicheck-filter>
              </ng-template>
            </kendo-grid-column>

            <ng-template kendoPagerTemplate>
              <kendo-pager-info *ngIf="pageSize > 0"></kendo-pager-info>
            </ng-template>

          </kendo-grid>

        </div>
      </div>
    </div>




    <div class="d-grid d-md-flex justify-content-md-end">
      <button casiDisableAfterClick [disabled]="disabledState$" type="button" class="btn btn-primary" (click)="printPDF()" style="margin-right: 5px">
        @if(disableState) {
        <fa-icon icon="spinner" [spin]="true"></fa-icon> Generating report
        } @else {
        Export PDF
        }
      </button>
      <button type="button" class="btn btn-secondary" (click)="closeWindow()" [hidden]="hideCloseBtn"> Close</button>
    </div>
  </fieldset>
</form>
